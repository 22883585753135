<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h1 class="toolbar--text">{{ $t('Top') }} {{ $t('NFTs') }}</h1>
          </v-col>
        </v-row>
        <v-row dense class="mb-1">
          <v-col
            cols="12"
            sm="6"
            lg="2"
            offset-sm="6"
            offset-lg="10"
          >
            <v-row dense align="center">
              <v-col cols="4" class="text-right">
                <small class="grey--text">{{ $t('Rows') }}</small>
              </v-col>
              <v-col cols="4" class="">
                <Menu
                  :selected-text="`${rowsPerPage.toString()}`"
                  :list-items="rows"
                  :button-size="`common_background`"
                  @click="onChangeRowsPerPage($event)"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="true"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onPreviousPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="false"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onNextPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-data-table
              :headers="header"
              :items="nftList"
              :loading="nftLoading"
              :headers-length="12"
              :header-props="{ sortIcon: 'mdi-chevron-up' }"
              no-data-text="No Record Found."
              :loader-height="1"
              item-key="nft_rank"
              hide-default-footer
              disable-pagination
              mobile-breakpoint="0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-center currency-details">
                    <span>{{ item.nft_rank }}</span>
                  </td>
                  <td
                    class="text-left currency-details secondary--text"
                  >
                    <v-btn
                      :href="item.website"
                      target="_blank"
                      class="text-capitalize no-spacing mr-2 elevation-0 no-hover-bg no-background"
                      :alt="$t('Website')"
                      :ripple="false"
                    >
                      <div>
                        <img
                          v-if="item.logo === null"
                          :alt="item.name"
                          :src="require(`@/assets/images/no-image.png`)"
                          class="currency-logo"
                        />
                        <img
                          v-else
                          :alt="item.name"
                          :lazy-src="item.logo"
                          :src="item.logo"
                          class="currency-logo"
                          @error="helper.imgerror"
                        />
                      </div>
                      <div class="ml-2">
                        <span>{{ item.name }}</span>
                      </div>
                    </v-btn>
                  </td>
                  <td class="text-right">
                    <span class="d-contents">
                      {{ helper.convertToBigNumber(item.unique_asset_transfers_at, 0) }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="d-contents">
                      {{ helper.convertToBigNumber(item.net_worth, 2) }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="d-contents">
                      {{ helper.convertToBigNumber(item.volume_7d, 2) }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="d-contents">
                      {{ helper.convertToBigNumber(item.sales_7d, 0) }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="d-contents">
                      {{ helper.convertToBigNumber(item.volume_alltime, 2) }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="d-contents">
                      {{ helper.convertToBigNumber(item.sales_alltime, 0) }}
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-divider />
        <v-row dense class="my-1">
          <v-col
            cols="12"
            sm="6"
            lg="2"
            offset-lg="10"
            offset-sm="6"
          >
            <v-row dense align="center">
              <v-col cols="4" class="text-right">
                <small class="grey--text">{{ $t('Rows') }}</small>
              </v-col>
              <v-col cols="4" class="">
                <Menu
                  :selected-text="`${rowsPerPage.toString()}`"
                  :list-items="rows"
                  :button-size="`common_background`"
                  @click="onChangeRowsPerPage($event)"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="true"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onPreviousPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="false"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onNextPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Loader from '@/components/molecules/Loader'
import helper from '@/utils/helper'
import { get, call, commit } from 'vuex-pathify'
import Menu from '@/components/atoms/Menu'

import {
  NFT_REQUEST,
} from '@/store/nft/action'

export default {
  name:'News',
  components: {
    Menu,
  },
  data() {
    return {
      helper: helper,
      selectedSort: 'ONE_DAY_VOLUME',
      page: 1,
      rows: [100, 200, 300, 400],
      rowsPerPage: 100,
      nftLoading: false,
      header: [
        {
          text: 'Rank',
          value: 'cmc_rank',
          align: 'center',
          width: '2%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'NFT',
          value: 'name',
          align: 'left',
          width: '12%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Assets',
          value: 'unique_asset_transfers_at',
          align: 'right',
          width: '10%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Net Worth',
          value: 'net_worth',
          align: 'right',
          width: '15%',
          sortable: true,
        },
        {
          text: 'Volume 7d',
          value: 'volume_7d',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Sales 7d',
          value: 'sales_7d',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Volume (All Time)',
          value: 'volume_alltime',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Sales (All Time)',
          value: 'sales_alltime',
          align: 'right',
          width: '10%',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    nftList: {
      get: get('nft/getNftList'),
      set: (value) => commit('nft/SET_NFTS', value),
    },
    selected_currency: get('getGlobalFiat'),
  },
  watch: {
    '$store.state.fiat': {
      handler(value) {
        this.onFetchNfts()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...call('nft',['NFT_REQUEST']),
    onPreviousPage() {
      if (this.page <= 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
      this.onFetchNfts()
    },
    onNextPage() {
      if (this.nftList.length <= 0) {
        const temp = this.page

        this.page = temp
      } else {
        this.page += 1
      }
      this.onFetchNfts()
    },
    onChangeRowsPerPage(row) {
      this.rowsPerPage = row
      this.page = 1
      this.onFetchNfts()
    },
    async onFetchNfts() {
      this.nftLoading = true

      await this.NFT_REQUEST({
        page: this.page,
        rows: this.rowsPerPage,
        currency: this.selected_currency,
      })

      this.nftLoading = false
    },
  },
}
</script>
